import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';

const setupAxiosInterceptors = (navigate: NavigateFunction) => {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      return Promise.reject(error);
    }
  );
};

export default setupAxiosInterceptors;
