import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import TransactionForm from './TransactionForm';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

export interface Transaction { 
    id: number;
    description: string;
    amount: number;
    date: string;
    type: 'income' | 'expense';
    category?: string;
    payee?: string;
    attachment?: string;
    attachmentType?: string;
}

interface Props {
    showForm :  boolean;
}

const TransactionList: React.FC<Props> = (props) => {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [editingTransaction, setEditingTransaction] = useState<Transaction | null>(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [transactionToDelete, setTransactionToDelete] = useState<number | null>(null);
const fetchTransactions = async () => {
            try {
                const endDate = new Date();
                const startDate = new Date();
                startDate.setMonth(endDate.getMonth() - 6);
                const formattedStartDate = startDate.toISOString().split('T')[0];
                const formattedEndDate = endDate.toISOString().split('T')[0];

                const response = await axios.get('/api/transactions/daterange', { 
                    params: { startDate: formattedStartDate, endDate: formattedEndDate }
                }); 
                setTransactions(response.data);
            } catch (error) {
                console.error('Fehler beim Abrufen der Transaktionen:', error);
            } 
        };


    useEffect(() => {
        
        fetchTransactions(); 
    }, []);

    useEffect(() => {
        fetchTransactions()
    }, [props.showForm]);

    const handleDelete = async (id: number) => { 
        try {
            await axios.delete(`/api/transactions/${id}`);
            setTransactions(transactions.filter(transaction => transaction.id !== id)); 
        } catch (error) {
            console.error('Fehler beim Löschen der Transaktion:', error); 
        }
    };

    const handleEdit = (transaction: Transaction) => { 
        setEditingTransaction(transaction);
    };

    const handleFormClose = () => {
        setEditingTransaction(null);
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 6);
        const formattedStartDate = startDate.toISOString().split('T')[0];
        const formattedEndDate = endDate.toISOString().split('T')[0];
        axios.get('/api/transactions/daterange', { 
            params: { startDate: formattedStartDate, endDate: formattedEndDate }
        }).then(response => setTransactions(response.data));
    };

    const confirmDelete = (id: number) => { 
        setTransactionToDelete(id); 
        setConfirmDialogOpen(true);
    };

    const handleConfirmDelete = async () => { 
        if (transactionToDelete !== null) {
            try {
                await axios.delete(`/api/transactions/${transactionToDelete}`); 
                setTransactions(transactions.filter(transaction => transaction.id !== transactionToDelete));
            } catch (error) {
                console.error('Fehler beim Löschen der Transaktion:', error);
            } 
            setConfirmDialogOpen(false); 
            setTransactionToDelete(null);
        } 
    };

    return ( 
        <>
            <Typography variant="h4" gutterBottom>
                Transaktionen der letzten 6 Monate
            </Typography>
            <TableContainer component={Paper}> 
                <Table>
                    <TableHead> 
                        <TableRow>
                            <TableCell>Beschreibung</TableCell> 
                            <TableCell>Betrag</TableCell> 
                            <TableCell>Datum</TableCell> 
                            <TableCell>Typ</TableCell> 
                            <TableCell>Kategorie</TableCell> 
                            <TableCell>Zahlungsempfänger</TableCell> 
                            <TableCell>Anhang</TableCell> 
                            <TableCell>Aktionen</TableCell>
                        </TableRow> 
                    </TableHead>
                    <TableBody> 
                        {transactions.map(transaction => (
                            <TableRow key={transaction.id}>
                                <TableCell>{transaction.description}</TableCell> 
                                <TableCell>{transaction.amount.toFixed(2)}</TableCell> 
                                <TableCell><input type="date" value={transaction.date} disabled/></TableCell>
                                <TableCell>{transaction.type === "expense" ? "Ausgabe" : "Einnahme"}</TableCell>
                                <TableCell>{transaction.category}</TableCell> 
                                <TableCell>{transaction.payee}</TableCell> 
                                <TableCell>
                                    {transaction.attachment && (
                                        <a href={`data:${transaction.attachmentType};base64,${transaction.attachment}`} download>Anhang herunterladen</a>
                                    )}
                                </TableCell> 
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(transaction)}> <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => confirmDelete(transaction.id)}>
                                        <DeleteIcon /> 
                                    </IconButton>
                                </TableCell> 
                            </TableRow>
                        ))}
                    </TableBody> 
                </Table>
            </TableContainer>
            {editingTransaction && <TransactionForm type={editingTransaction.type} transaction={editingTransaction} onClose={handleFormClose} />}
            <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
            >
                <DialogTitle>Transaktion löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Sind Sie sicher, dass Sie diese Transaktion löschen möchten?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)}>Abbrechen</Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    ); 
};

export default TransactionList;
