import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box, Typography,
    TextField, MenuItem, Select, InputLabel, FormControl, Button, Modal,
    Chip,
    Autocomplete
} from '@mui/material';
import DatePicker from 'react-multi-date-picker';
import { Transaction } from './TransactionList';
import { PeriodicTransaction } from './PeriodicTransactionList';

interface TransactionFormProps {
    type: 'income' | 'expense' | 'periodic' | null;
    onClose: () => void;
    transaction?: Transaction | PeriodicTransaction;
}

const TransactionForm: React.FC<TransactionFormProps> = ({ type, onClose, transaction }) => {
    const [description, setDescription] = useState(transaction?.description || '');
    const [amount, setAmount] = useState<number | string>(transaction?.amount || '');
    const [date, setDate] = useState(transaction && 'date' in transaction ? transaction.date : new Date().toISOString().split('T')[0]);
    const [category, setCategory] = useState(transaction?.category || '');
    const [payee, setPayee] = useState(transaction?.payee || '');
    const [periodicity, setPeriodicity] = useState(transaction && 'periodicity' in transaction ? transaction.periodicity : '');
    const [customDates, setCustomDates] = useState<string[]>(transaction && 'customDates' in transaction ? (transaction.customDates as unknown as string[]) : []);
    const [categories, setCategories] = useState<string[]>([]);
    const [payees, setPayees] = useState<string[]>([]);
    const [attachment, setAttachment] = useState<File | null>(null);
    const [transactionType, setTransactionType] = useState<'income' | 'expense'>(transaction?.type || 'expense');
    const [customMonths, setCustomMonths] = useState<number[]>([]);
    const [nextExecutionDate, setNextExecutionDate] = useState(transaction && 'nextExecutionDate' in transaction ? transaction.nextExecutionDate : new Date().toISOString().split('T')[0]);

    useEffect(() => {
        const fetchSuggestions = async (type: 'category' | 'payee', query: string) => {
            try {
                const response = await axios.get(`/api/suggestions/${type}/${query}`);
                if (type === 'category') {
                    setCategories(response.data);
                } else {
                    setPayees(response.data);
                }
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        if (category) {
            fetchSuggestions('category', category);
        }

        if (payee) {
            fetchSuggestions('payee', payee);
        }
    }, [category, payee]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('description', description);
        formData.append('amount', String(amount));
        if (date) formData.append('date', date);
        formData.append('type', type === 'periodic' ? transactionType : type || '');
        formData.append('category', category);
        formData.append('payee', payee);
        if (periodicity) formData.append('periodicity', periodicity);
        formData.append('customDates', JSON.stringify(customDates));
        formData.append('customMonths', JSON.stringify(customMonths));
        formData.append('nextExecutionDate', nextExecutionDate);

        if (attachment) {
            formData.append('attachment', attachment);
        }

        try {
            await axios.post(type === 'periodic' ? '/api/periodic-transactions' : '/api/transactions',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            onClose();
        } catch (error) {
            console.error('Error saving transaction:', error);
        }
    };

    const handleCustomDatesChange = (dates: any) => {
        setCustomDates(dates.map((date: any) => date.toISOString().split('T')[0]));
    };

    const germanMonths = [
        { value: 1, label: 'Januar' },
        { value: 2, label: 'Februar' },
        { value: 3, label: 'März' },
        { value: 4, label: 'April' },
        { value: 5, label: 'Mai' },
        { value: 6, label: 'Juni' },
        { value: 7, label: 'Juli' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'Oktober' },
        { value: 11, label: 'November' },
        { value: 12, label: 'Dezember' }
    ];

    return (
        <Modal open={true} onClose={onClose}>
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <Typography variant="h6" component="h2">
                    {type === 'income' ? 'Einnahme erfassen' : type === 'expense' ? 'Ausgabe erfassen' : 'Dauerauftrag erfassen'}
                </Typography>
                <form onSubmit={handleSubmit}>
                <Autocomplete
                        freeSolo
                        options={payees}
                        inputValue={payee}
                        onInputChange={(event, newValue) => setPayee(newValue)}
                        renderInput={(params) => (
                            <TextField {...params}
                                label="Zahlungsempfänger"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                    <TextField fullWidth
                        margin="normal"
                        label="Beschreibung"
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                    <TextField fullWidth
                        margin="normal"
                        label="Betrag"
                        variant="outlined"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        inputProps={{ step: '0.01' }}
                        required
                    />
                    <Autocomplete
                        freeSolo
                        options={categories}
                        inputValue={category}
                        onInputChange={(event, newValue) => setCategory(newValue)}
                        renderInput={(params) => (
                            <TextField {...params}
                                label="Kategorie"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                    {type !== 'periodic' && (
                        <TextField fullWidth
                            margin="normal"
                            label="Datum"
                            variant="outlined"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    )}
                    {type === 'periodic' && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="transaction-type-label">Typ</InputLabel>
                            <Select
                                labelId="transaction-type-label"
                                value={transactionType}
                                label="Typ"
                                onChange={(e) => setTransactionType(e.target.value as 'income' | 'expense')}
                                required
                            >
                                <MenuItem value="income">Einnahme</MenuItem>
                                <MenuItem value="expense">Ausgabe</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    
                   
                    

                    {type === 'periodic' && (
                        
                        <>
                                                    <TextField fullWidth
                                margin="normal"
                                label="Startdatum"
                                variant="outlined"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={nextExecutionDate}
                                onChange={(e) => setNextExecutionDate(e.target.value)}
                                required
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="periodicity-label">Periodizität</InputLabel>
                                <Select
                                    labelId="periodicity-label"
                                    value={periodicity}
                                    label="Periodizität"
                                    onChange={(e) => setPeriodicity(e.target.value as string)}
                                >
                                    <MenuItem value="daily">Täglich</MenuItem>
                                    <MenuItem value="weekly">Wöchentlich</MenuItem>
                                    <MenuItem value="monthly">Monatlich</MenuItem>
                                    <MenuItem value="quarterly">Quartal</MenuItem>
                                    <MenuItem value="yearly">Jährlich</MenuItem>
                                    <MenuItem value="tertiary">Tertiär</MenuItem>
                                    <MenuItem value="custom">Benutzerdefiniert</MenuItem>
                                </Select>
                            </FormControl>
                            {periodicity === 'custom' && (
                                <Box>
                                    
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="custom-months-label">Benutzerdefinierte Monate</InputLabel>
                                        <Select
                                            labelId="custom-months-label"
                                            label="Benutzerdefinierte Monate"
                                            multiple
                                            value={customMonths}
                                            onChange={(e) => setCustomMonths(e.target.value as number[])}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={germanMonths.find(month => month.value === value)?.label} />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {germanMonths.map((month) => (
                                                <MenuItem key={month.value} value={month.value}>
                                                    {month.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}

                        </>
                    )}
                                        <input accept="*/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={(e) => setAttachment(e.target.files ? e.target.files[0] : null)}
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" component="span" sx={{ mt: 2, mb: 2 }}>
                            Anhang hochladen
                        </Button>
                    </label>
                    {attachment && (
                        <Chip
                            label={attachment.name}
                            onDelete={() => setAttachment(null)}
                            sx={{ mt: 2 }}
                        />
                    )}
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                        Speichern
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default TransactionForm;