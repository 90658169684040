import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ChangePassword: React.FC = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleChangePassword = async (event: React.FormEvent) => {
    event.preventDefault();
    if (newPassword !== confirmation) {
      setError('New passwords do not match');
      return;
    }
    try {
      await axios.post('/api/change-password', { oldPassword, newPassword });
      setSuccess('Password changed successfully');
      setError('');
      try {
        await axios.post('/api/logout');
        navigate('/login');
      } catch (error) {
        console.error('Logout failed', error);
      }
    } catch (err) {
      setError('Error changing password');
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Box width={300}>
          <Typography variant="h4" align="center" gutterBottom>Change Password</Typography>
          <form onSubmit={handleChangePassword}>
            <TextField
              label="Old Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            <TextField
              label="New Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <TextField
              label="Confirm New Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmation}
              onChange={(e) => setConfirmation(e.target.value)}
              required
            />
            {error && <Typography color="error">{error}</Typography>}
            {success && <Typography color="primary">{success}</Typography>}
            <Button type="submit" variant="contained" color="primary" fullWidth>Change Password</Button>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default ChangePassword;
