import React, { useState, useEffect } from 'react';
import { Container, AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Divider, Typography, Button } from '@mui/material';
import { Menu as MenuIcon, Add as AddIcon, List as ListIcon } from '@mui/icons-material';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import TransactionForm from './TransactionForm';
import TransactionList from './TransactionList';
import PeriodicTransactionList from './PeriodicTransactionList';
import CategoryChart from './CategoryChart';
import CashFlowChart from './CashFlowChart';
import Login from './Login';
import ChangePassword from './ChangePassword';
import setupAxiosInterceptors from '../setupAxiosInterceptors';
import axios from 'axios';

const MainMenu: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [formType, setFormType] = useState<'income' | 'expense' | 'periodic' | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [showPeriodicTransactions, setShowPeriodicTransactions] = useState(false);
  const [showCategoryChart, setShowCategoryChart] = useState(true);
  const [showCashFlowChart, setShowCashFlowChart] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setupAxiosInterceptors(navigate);
  }, [navigate]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleOpenForm = (type: 'income' | 'expense' | 'periodic') => {
    setFormType(type);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setFormType(null);
  };

  const handleShowTransactions = () => {
    setShowTransactions(true);
    setShowPeriodicTransactions(false);
    setShowCategoryChart(false);
    setShowCashFlowChart(false);
  };

  const handleShowPeriodicTransactions = () => {
    setShowPeriodicTransactions(true);
    setShowTransactions(false);
    setShowCategoryChart(false);
    setShowCashFlowChart(false);
  };

  const handleShowCategoryChart = () => {
    setShowCategoryChart(true);
    setShowTransactions(false);
    setShowPeriodicTransactions(false);
    setShowCashFlowChart(false);
  };

  const handleShowCashFlowChart = () => {
    setShowCashFlowChart(true);
    setShowCategoryChart(false);
    setShowTransactions(false);
    setShowPeriodicTransactions(false);
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <Container>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Haushaltsbuch
          </Typography>
          <Button color="inherit" onClick={() => navigate('/change-password')}>Change Password</Button>
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List>
            <ListItem button onClick={() => handleOpenForm('expense')}>
              <ListItemIcon><AddIcon /></ListItemIcon>
              <ListItemText primary="Ausgabe erfassen" />
            </ListItem>
            <ListItem button onClick={() => handleOpenForm('periodic')}>
              <ListItemIcon><AddIcon /></ListItemIcon>
              <ListItemText primary="Dauerauftrag erfassen" />
            </ListItem>
            <ListItem button onClick={() => handleOpenForm('income')}>
              <ListItemIcon><AddIcon /></ListItemIcon>
              <ListItemText primary="Einnahme erfassen" />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleShowTransactions}>
              <ListItemIcon><ListIcon /></ListItemIcon>
              <ListItemText primary="Transaktionen anzeigen" />
            </ListItem>
            <ListItem button onClick={handleShowPeriodicTransactions}>
              <ListItemIcon><ListIcon /></ListItemIcon>
              <ListItemText primary="Daueraufträge anzeigen" />
            </ListItem>
            <ListItem button onClick={handleShowCategoryChart}>
              <ListItemIcon><ListIcon /></ListItemIcon>
              <ListItemText primary="Ausgaben nach Kategorie" />
            </ListItem>
            <ListItem button onClick={handleShowCashFlowChart}>
              <ListItemIcon><ListIcon /></ListItemIcon>
              <ListItemText primary="Cashflow" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      {showForm && <TransactionForm type={formType} onClose={handleCloseForm} />}
      {showPeriodicTransactions ? (
        <PeriodicTransactionList />
      ) : showTransactions ? (
        <TransactionList showForm={showForm} />
      ) : showCategoryChart ? (
        <CategoryChart showForm={showForm} />
      ) : (
        <CashFlowChart />
      )}
    </Container>
  );
};

const AppRoutes: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/" element={<MainMenu />} />
    </Routes>
  </Router>
);

export default AppRoutes;
