import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    Plugin
} from 'chart.js';
import axios from 'axios';
import { Container, TextField, Button, Modal, Box, Typography, List, ListItem, ListItemText } from '@mui/material';

interface Transaction {
    id: number;
    description: string;
    amount: number;
    date: string;
    category: string;
    payee: string;
    type: string;
}

interface CategorizedTransaction {
    category: string;
    total: number;
}

// Registrierung der Komponenten
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

// Plugin zum Anzeigen von Werten im Diagramm
const dataLabelPlugin: Plugin<'pie'> = {
    id: 'dataLabel',
    afterDatasetDraw(chart) {
        const { ctx, data } = chart;
        const dataset = data.datasets[0];
        const meta = chart.getDatasetMeta(0);
        meta.data.forEach((element, index) => {
            const { x, y } = element.tooltipPosition(true);
            const value = dataset.data[index] as number;
            ctx.fillStyle = 'black';
            ctx.font = '16px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(`${value.toFixed(2)} €`, x, y);
        });
    }
};

ChartJS.register(dataLabelPlugin);

interface CategoryChartProps {
    showForm: boolean;
}

const CategoryChart: React.FC<CategoryChartProps> = (props) => {
    const [transactions, setTransactions] = useState<CategorizedTransaction[]>([]);
    const [detailedTransactions, setDetailedTransactions] = useState<Transaction[]>([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const navigate = useNavigate();

    const formatDate = (dateString: string): string => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };


    function getMonthDates(date = new Date()) {
        // Stellen Sie sicher, dass wir mit einem Date-Objekt arbeiten
        const d = new Date(date);
        
        // Setzen Sie die Uhrzeit auf Mitternacht, um Zeitzonenprobleme zu vermeiden
        d.setHours(0, 0, 0, 0);
      
        // Erster Tag des Monats
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
        
        // Letzter Tag des Monats
        const lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
      
        // Formatierungsfunktion für YYYY-MM-DD
        const formatDate = (date : any) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
      
        return {
          firstDay: formatDate(firstDay),
          lastDay: formatDate(lastDay),
          today: formatDate(d)
        };
      }

    useEffect(() => {

// Aktuelles Datum
const d = new Date();

// Setzen Sie die Uhrzeit auf Mitternacht, um Zeitzonenprobleme zu vermeiden
d.setHours(0, 0, 0, 0);

// Erster Tag des Monats
const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);

// Letzter Tag des Monats
const lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);

// Formatierungsfunktion für YYYY-MM-DD
const formatDate = (date : any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const ersterTagFormatiert = formatDate(firstDay);
const letzterTagFormatiert = formatDate(lastDay);
const heuteFormatiert = formatDate(d);

        console.log("Erster Tag des Monats:", ersterTagFormatiert);
        console.log("Letzter Tag des Monats:", letzterTagFormatiert);
        console.log("Heutiges Datum:", heuteFormatiert);


        setStartDate(ersterTagFormatiert);
        setEndDate(letzterTagFormatiert);
        fetchTransactions(ersterTagFormatiert, letzterTagFormatiert);
    }, []);

    useEffect(() => {
        if (startDate && endDate && startDate !== '' && endDate !== '') {
            fetchTransactions(startDate, endDate);
        }
    }, [props.showForm]);

    const fetchTransactions = async (startDate: string, endDate: string) => {
        try {
            const response = await axios.get<Transaction[]>('/api/transactions/daterange', {
                params: { startDate, endDate }
            })
            const expenses = response.data.filter(transaction => transaction.type === 'expense');
            const categorized = expenses.reduce((acc: { [key: string]: number }, transaction) => {
                if (!acc[transaction.category]) {
                    acc[transaction.category] = 0;
                }
                acc[transaction.category] += transaction.amount;
                return acc;
            }, {});
            const categorizedTransactions = Object.keys(categorized).map(category => ({
                category,
                total: categorized[category]
            }));
            setTransactions(categorizedTransactions);
            setTotalExpenses(expenses.reduce((sum, transaction) => sum + transaction.amount, 0));
        } catch (error : any) {
          
                if (error.response && error.response.status === 401) {
                  navigate('/login');
                } else {
                  console.error('Fehler beim Abrufen der Transaktionen', error);
                }
           
        }
    };

    const fetchDetailedTransactions = async (category: string) => {
        try {
            const response = await axios.get<Transaction[]>(`/api/transactions/category/${category}`, {
                params: { startDate, endDate }
            });
            const expenses = response.data.filter(transaction => transaction.type === 'expense');
            setDetailedTransactions(expenses);
        } catch (error : any) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
              } else {
                console.error('Fehler beim Abrufen der Transaktionen', error);
              }
        }
    };

    const handleDateChange = () => {
        fetchTransactions(startDate, endDate);
    };

    const handleCategoryClick = (category: string) => {
        setSelectedCategory(category);
        fetchDetailedTransactions(category);
        setModalOpen(true);
    };

    const data = {
        labels: transactions.map(t => t.category),
        datasets: [
            {
                label: 'Betrag',
                data: transactions.map(t => t.total),
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#FF9F40',
                    '#4BC0C0',
                    '#9966FF',
                    '#FF6384',
                ],
                borderColor: '#FFFFFF',
                borderWidth: 1,
            },
        ],
    };

    return (
        <Container>
            <div style={{ display: 'block', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>

            <Typography variant="h6">Gesamtausgaben: {totalExpenses.toFixed(2)} €</Typography>
            </div>
            <div >
            <div style={{ display: 'block', alignItems: 'center', marginBottom: '20px' }}>
                    <TextField
                    label="Startdatum"
                    type="date"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: '10px' }}
                />
                <TextField
                    label="Enddatum"
                    type="date"
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: '10px' }}
                />
                </div>
                <div style={{ display: "block", alignItems: 'center', marginBottom: '20px' }}>
                <Button variant="contained" color="primary" onClick={handleDateChange}>
                    Aktualisieren
                </Button>
                </div>
                
            </div>
            <div style={{ height: '70vh', maxWidth: '100%' }}>
            <Pie

                data={data}
                options={{
                    onClick: (event, elements) => {
                        if (elements.length > 0) {
                            const category = transactions[elements[0].index].category;
                            handleCategoryClick(category);
                        }
                    },
                }}
            />
            </div>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Transaktionen für Kategorie: {selectedCategory}
                    </Typography>
                    <List>
                        {detailedTransactions.map(transaction => (
                            <ListItem key={transaction.id}>
                                <ListItemText
                                    primary={transaction.payee}
                                    secondary={`${formatDate(transaction.date)} - ${transaction.amount.toFixed(2)} € für ${transaction.description}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Button variant="contained" onClick={() => setModalOpen(false)} sx={{ mt: 2 }}>
                        Schließen
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
};

export default CategoryChart;
