import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material'; 
import TransactionForm from './TransactionForm';

export interface PeriodicTransaction { 
    id: number;
    description: string;
    amount: number;
    type: 'income' | 'expense';
    periodicity: string;
    category?: string;
    payee?: string;
    nextExecutionDate: string;
    attachment?: string;
    attachmentType?: string;
}

const PeriodicTransactionList: React.FC = () => {
    const [periodicTransactions, setPeriodicTransactions] = useState<PeriodicTransaction[]>([]); 
    const [editingTransaction, setEditingTransaction] = useState<PeriodicTransaction | null>(null);

    useEffect(() => {
        const fetchPeriodicTransactions = async () => {
            try {
                const response = await axios.get('/api/periodic-transactions'); 
                setPeriodicTransactions(response.data);
            } catch (error) {
                console.error('Error fetching periodic transactions:', error);
            }
        };
        fetchPeriodicTransactions(); 
    }, []);

    const handleDelete = async (id: number) => { 
        try {
            await axios.delete(`/api/periodic-transactions/${id}`);
            setPeriodicTransactions(periodicTransactions.filter(transaction => transaction.id !== id)); 
        } catch (error) {
            console.error('Error deleting transaction:', error); 
        }
    };

    const handleEdit = (transaction: PeriodicTransaction) => { 
        setEditingTransaction(transaction);
    };

    const handleFormClose = () => {
        setEditingTransaction(null);
        axios.get('/api/periodic-transactions').then(response => setPeriodicTransactions(response.data));
    };

    return ( 
        <>
            <TableContainer component={Paper}> 
                <Table>
                    <TableHead> 
                        <TableRow>
                            <TableCell>Beschreibung</TableCell> 
                            <TableCell>Betrag</TableCell> 
                            <TableCell>Typ</TableCell> 
                            <TableCell>Periodizität</TableCell> 
                            <TableCell>Zahlungsempfänger</TableCell> 
                            <TableCell>Nächste Ausführung</TableCell> 
                            <TableCell>Anhang</TableCell> 
                            <TableCell>Aktionen</TableCell>
                        </TableRow> 
                    </TableHead>
                    <TableBody> 
                        {periodicTransactions.map(transaction => (
                            <TableRow key={transaction.id}> 
                                <TableCell>{transaction.description}</TableCell> 
                                <TableCell>{transaction.amount.toFixed(2) + " €"}</TableCell> 
                                <TableCell>{transaction.type === "income" ? "Einnahme" : "Ausgabe"}</TableCell> 
                                <TableCell>{transaction.periodicity}</TableCell> 
                                <TableCell>{transaction.payee}</TableCell> 
                                <TableCell>{transaction.nextExecutionDate}</TableCell> 
                                <TableCell>
                                    {transaction.attachment && (
                                        <a href={`data:${transaction.attachmentType};base64,${transaction.attachment}`} download>Anhang herunterladen</a>
                                    )}
                                </TableCell> 
                                <TableCell>
                                   
                                    <IconButton onClick={() => handleDelete(transaction.id)}>
                                        <DeleteIcon /> 
                                    </IconButton>
                                </TableCell> 
                            </TableRow>
                        ))}
                    </TableBody> 
                </Table>
            </TableContainer>
            {editingTransaction && <TransactionForm type="periodic" transaction={editingTransaction} onClose={handleFormClose} />}
        </>
    ); 
};

export default PeriodicTransactionList;
