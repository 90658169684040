import React, { useState, useEffect } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend
} from 'chart.js';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Transaction {
  id: number;
  description: string;
  amount: number;
  date: string;
  type: string;
  category: string;
  payee: string;
}

ChartJS.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const CashFlowChart = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [cashFlow, setCashFlow] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
    const lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const ersterTagFormatiert = formatDate(firstDay);
    const letzterTagFormatiert = formatDate(lastDay);
    setStartDate(ersterTagFormatiert);
    setEndDate(letzterTagFormatiert);
    fetchTransactions(ersterTagFormatiert, letzterTagFormatiert);
  }, []);

  const fetchTransactions = async (startDate: string, endDate: string) => {
    try {
      const response = await axios.get<Transaction[]>('/api/transactions/daterange', {
        params: { startDate, endDate }
      });
      setTransactions(response.data);
      calculateCashFlow(response.data);
    } catch (error : any) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else {
        console.error('Fehler beim Abrufen der Transaktionen', error);
      }
    }
  };

  const calculateCashFlow = (transactions: Transaction[]) => {
    const income = transactions.filter(t => t.type === 'income').reduce((sum, t) => sum + t.amount, 0);
    const expense = transactions.filter(t => t.type === 'expense').reduce((sum, t) => sum + t.amount, 0);
    setTotalIncome(income);
    setTotalExpense(expense);
    setCashFlow(income - expense);
  };

  const handleDateChange = () => {
    fetchTransactions(startDate, endDate);
  };

  const pieData = {
    labels: ['Ausgaben', 'Cashflow'],
    datasets: [
      {
        label: 'Betrag',
        data: [totalExpense, cashFlow],
        backgroundColor: ['#FF6384', cashFlow >= 0 ? '#4BC0C0' : '#FFCD56'],
        borderColor: '#FFFFFF',
        borderWidth: 1,
      },
    ],
  };

  const barData = {
    labels: ['Einnahmen', 'Ausgaben', 'Cashflow'],
    datasets: [
      {
        label: 'Betrag',
        data: [totalIncome, totalExpense, cashFlow],
        backgroundColor: ['#36A2EB', '#FF6384', cashFlow >= 0 ? '#4BC0C0' : '#FFCD56'],
        borderColor: '#FFFFFF',
        borderWidth: 1,
      },
    ],
  };

  const renderChart = () => {
    if (cashFlow < 0) {
      return (
        <>
          <Typography variant="h6" color="error">Negativer Cashflow!</Typography>
          <Bar data={barData} />
        </>
      );
    } else {
      return (
        <div style={{ height: '50vh', maxWidth: '100%' }}>
          <Pie data={pieData} />
        </div>
      );
    }
  };

  return (
    <Container>
      <h2>Cashflow</h2>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <TextField
          label="Startdatum"
          type="date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Enddatum"
          type="date"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <Button variant="contained" color="primary" onClick={handleDateChange}>Aktualisieren</Button>
      </Box>
      {renderChart()}
      <Typography variant="h6" mt={4}>Übersicht</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Typ</TableCell>
              <TableCell>Betrag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Einnahmen</TableCell>
              <TableCell>{totalIncome.toFixed(2)} €</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ausgaben</TableCell>
              <TableCell>{totalExpense.toFixed(2)} €</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cashflow</TableCell>
              <TableCell>{cashFlow.toFixed(2)} €</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default CashFlowChart;
